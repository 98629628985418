<template>
  <div>
    <h5 class="mb-4">
      Please enter your bank details below
    </h5>

    <BankInfoForm
      :update-info="showEditForm"
      full-layout
      onboarding
      @success="onSuccess"
      @cancel="$emit('cancel')"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

import BankInfoForm from '@/components/seller/BankInfoForm.vue'
import { SELLER_ONBOARDING_STATUS } from '@/store/modules/auth.store'
import pageTrackingMixin from '@/mixins/ms-clarity-mixin'

export default {
  name: 'BankInfoOnboarding',

  title: 'Enter bank details',

  components: {
    BankInfoForm,
  },

  mixins: [pageTrackingMixin],

  computed: {
    ...mapGetters('auth', [
      'accountOnboardingStatus'
    ]),

    showEditForm() {
      return this.accountOnboardingStatus === SELLER_ONBOARDING_STATUS.PENDING_DOCUMENT_SIGNING
    }
  },

  methods: {
    onSuccess() {
      this.$emit('success')
    }
  },
}
</script>

<style>

</style>