<template>
  <div class="d-flex flex-column">
    <b-row class="mx-4 mt-4 d-flex align-items-center">
      <h4>
        <strong>Review Creatives</strong>
      </h4>

      
      <b-col
        cols="12"
        lg="4"
        class="ml-auto text-right pr-0"
      >
        <b-form-input
          v-model="filterByCreativeId"
          type="search"
          placeholder="Search by Creative Id or SMID"
          class="custom-form-input--sm"
          debounce="500"
        />
      </b-col>
    </b-row>

    <div class="panel-holder mt-2">
      <div v-if="suggestedCreativesForReviewByBuyer.length">
        <h5 class="mx-4 mt-3">
          <font-awesome-icon
            :icon="['fas', 'star']"
            class="mr-1"
          />
          Suggested Creatives for Review
        </h5>
        <Panel
          loader-text="Loading Creatives"
          :loading="loadingCreatives"
          hide-header
          :icon="['fas', 'star']"
          class="mt-3"
        >
          <template v-slot:content>
            <ListCreativeReviews
              :creative-reviews="suggestedCreativesForReviewByBuyer"
              simple-view
              :filter-by-creative-id="filterByCreativeId"
            />
          </template>
        </Panel>
      </div>
      

      <h5 class="mx-4 mt-3">
        Creatives
      </h5>
      <Panel
        size="small"
        loader-text="Loading Creatives"
        :loading="loadingCreatives"
        hide-header
        class="mt-3"
      >
        <template v-slot:content>
          <ListCreativeReviews
            :creative-reviews="allCreativeReviewsFiltered"
            :filter-by-creative-id="filterByCreativeId"
            :status-filter="statusFilter"
          />
        </template>
      </Panel>
    </div>
  </div>
</template>
        
<script>
import { mapActions, mapState, mapGetters, mapMutations } from 'vuex'

import { showErrorMessage } from '@/notification-utils'
import formInputMixin from '@/mixins/formInputMixin'
import pageTrackingMixin from '@/mixins/ms-clarity-mixin'
import copyToClipboardMixin from '@/mixins/copy-to-clipboard-mixin'
import ListCreativeReviews from './ListCreativeReviews.vue'
        
export default {
  name: 'ReviewCreatives',

  components: {
    ListCreativeReviews,
  },
        
  title: 'Review Creatives',
      
  mixins: [formInputMixin, copyToClipboardMixin, pageTrackingMixin],
        
  data() {
    return {
      loadingCreatives: false,

      filterByCreativeId: '',

      statusFilter: 'Pending',
    }
  },
  
  computed: {
    ...mapState('creative', [ 'creativeReviews','selectedCreativeReview' ]),
    ...mapGetters('creative', ['getSelectedCreativeReviewUrls', 'suggestedCreativesForReviewByBuyer']),

    allCreativeReviewsFiltered() {
      return [...this.creativeReviews.filter(creative => !creative.isRecommendedForReview)]
    }
  },
    
  async created() {
    try {   
      this.loadingCreatives = true   
      
      await this.getCreativeReviews()
      
      this.statusFilter = this.startCase(this.$route.query.status ?? 'Pending')
    } catch (error) {
      showErrorMessage(error.response?.data?.title)
    }
    finally {
      this.loadingCreatives = false
    }
  },
  
  methods: {
    ...mapActions('creative', [ 'getCreativeReviews','getCreativeReview', 'approveCreativeReview', 'rejectCreativeReview' ]),

    ...mapMutations('creative', ['SET_SELECTED_CREATIVE_REVIEW']),
  
    getStatusMap(status) {
      const statusToVariantMap = {
        Pending: {variant:'secondary', text:'Review Pending'},
        Rejected: {variant:'danger', text:'Rejected'},
        Approved: {variant:'success', text:'Approved'},
        Disabled: {variant:'warning', text:'Disabled'}        
      }
      return statusToVariantMap[status] ?? {variant:'dark', text: ''}
    },

    showConfirmApproveCreative() {      
      this.$bvModal.show('confirm-approve-modal')
      this.lightboxVisible = false
    },

    showConfirmRejectCreative() {      
      this.$bvModal.show('confirm-reject-modal')
      this.lightboxVisible = false
    },
  
    async loadCreativeReview(review){
      if (!review.creative.files || !review.creative.files.length) {
        this.SET_SELECTED_CREATIVE_REVIEW(review)
        this.$bvModal.show('review-modal')
        return
      }

      try {
        this.currentLightboxIndex = 0

        this.$set(this.loadingFiles, review.id, true)

        await this.getCreativeReview(review.id)

        this.lightboxVisible = true

        this.shouldReview = true
      } catch (error) {
        showErrorMessage("Failed to open files")
      } finally {
        this.$set(this.loadingFiles, review.id, false)
      }
    },

    async approveCreative(){
      try {
        this.isLoadingApproved = true

        await this.approveCreativeReview(this.selectedCreativeReview.id)

        this.$bvModal.hide('confirm-approve-modal')
        this.$bvModal.hide('review-modal')
      } catch (error) {
        showErrorMessage("Failed to approve creative")
      } finally {
        this.isLoadingApproved = false
      }
    },

    async rejectCreative(){
      try {
        this.isLoadingRejected = true

        await this.rejectCreativeReview({
          id: this.selectedCreativeReview.id, 
          reason: this.selectedCreativeReview.rejectionReason
        })

        this.$bvModal.hide('confirm-reject-modal')
        this.$bvModal.hide('review-modal')
      } catch (error) {
        showErrorMessage("Failed to reject creative")
      } finally {
        this.isLoadingRejected = false
      }
    },

    onImageIndexChange(oldIndex, newIndex) {
      this.currentLightboxIndex = newIndex
    }
  }
}
</script>
  <style lang="scss" scoped>
  </style>