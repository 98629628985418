import Clarity from '@microsoft/clarity'
import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters('auth', ['accountDetails', 'userId'])
  },

  created() {
    const debug = process.env.NODE_ENV !== 'production'

    if (!debug) {
      this.initClarity()
    }
  },

  methods: {
    initClarity() {
      try {
        Clarity.identify(
          this.userId,
          '',
          this.$options.name,
          this.accountDetails.name
        )
      } catch (error) {
        console.error('Error calling Clarity Identify API', error)
      }
    }
  }
}
