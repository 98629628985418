import { mapActions } from 'vuex'

import BaseESignOnboarding from './BaseESignOnboarding.vue'

const BuyerESignOnboarding = BaseESignOnboarding.extend({
  name: 'BuyerESignOnboarding',

  methods: {
    ...mapActions('account', {
      getPendingDocumentBundleStatusForAccount: 'getPendingDocumentBundleStatusForBuyer',
      createOnboardingDocsForAccount: 'createOnboardingDocsForBuyer'
    }),
  }
})

export default BuyerESignOnboarding