import { render, staticRenderFns } from "./BaseFormToggle.vue?vue&type=template&id=263e6810&scoped=true&"
import script from "./BaseFormToggle.vue?vue&type=script&lang=js&"
export * from "./BaseFormToggle.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "263e6810",
  null
  
)

export default component.exports