<template>
  <Panel size="small">
    <template v-slot:header>
      <span data-cy="heading">Audience</span>
    </template>

    <template v-slot:content>
      <div>
        <BaseFormToggle
          id="buyer-create-campaign__audience__search-location"
          v-model="searchLocation"
          internal-platform-input
          data-cy="toggle-search-location"
          label="Location"
          :checked-value="true"
          :unchecked-value="false"
          has-tooltip
        />

        <BaseFormSelect
          v-if="searchLocation"
          id="buyer-create-campaign__audience__location-no-icp"
          v-model="noIcp"
          internal-platform-input
          :horizontal="true"         
          :small="true"
          data-cy="toggle-search-location"
          label="Use Location From"
          :options="icpOptions"
          has-tooltip
        />

        <BaseButtonGroup
          v-if="searchLocation"
          id="buyer-create-campaign__audience__location-filter-type"
          :horizontal="true"
          name="LocationFilterType"
          label="Location Type"
          :selected-option="locationFilterType"
          data-cy="button-group-call-type"
          :click-handler="updateLocationFilterType"
          internal-platform-input
          :options="['States', 'ZipCodes']"
          :disabled-options="getLocationFilterTypeDisabledOptions"
          has-tooltip
        />

        <validation-provider
          v-if="showLocationStates"
          name="States"
          rules="required"
        >
          <b-form-group
            slot-scope="{ errors }"
            label-for="input-locations"
            label-class="custom-form-input-label--internal"
            label-cols="3"
            content-cols="5"
            label-align="right"
          >
            <v-multiselect-listbox
              id="input-locations"
              v-model="selectedLocations"
              data-cy="multiselect-locations"
              class="msl-multi-select--full-width"
              :options="stateOptions"
              :reduce-display-property="(option) => option.label"
              :reduce-value-property="(option) => option.value"
              selected-no-options-text="No location selected"
              search-input-class="custom-form-input custom-form-input--sm"
              no-options-text="No states"
              :show-select-all-buttons="true"
              selected-options-placeholder="Search selected"
            />

            <div class="text-danger mt-1">
              {{ errors[0] }}
            </div>
          </b-form-group>
        </validation-provider>        

        <BaseFileUpload
          v-if="showLocationZipCodes"
          id="buyer-create-campaign__audience__upload-zip-codes"
          v-model="zipCodesFile"
          internal-platform-input
          :horizontal="true"
          data-cy="file-input-zip-codes"
          name="ZIP codes file"
          accept="text/plain, text/csv"
          rules="size:1024|zipcode"
          :placeholder="getZipCodeFileName"         
          label="Upload zip codes list"
          icon="upload"
          has-tooltip
          width="3"          
          @input="parseZipCodes"          
        >
          <template v-slot:helpText>
            <b-form-text
              v-if="!showPreviouslyUploadedZipCodes"
            >
              <div>
                Upload a txt file containing list of zip codes                
              </div>
            </b-form-text>
            <b-form-text
              v-if="showPreviouslyUploadedZipCodes"
            >
              <div>
                File {{ buyerCampaign.audience.zipCodesFileName }} has {{ buyerCampaign.audience.zipCodesCount }} entries
              </div>
            </b-form-text>
          </template>
        </BaseFileUpload>
      </div>
    </template>
  </Panel>
</template>

<script>
import { mapState, mapMutations } from 'vuex'

export default {
  name: 'Audience',  

  data() {
    return {
      zipCodesFile: null
    }
  },  

  computed: {
    ...mapState('campaign', ['buyerCampaign']),

    ...mapState('country', ['usStates']),
    
    stateOptions() {
      let stateOptions = []

      stateOptions = this.usStates.map(state => {
        return {
          value: state.abbreviation,
          label: state.name
        }
      })
      
      return stateOptions      
    },
    
    searchLocation: {
      get() {
        return this.buyerCampaign.audience.searchLocation
      },
      set(value) {
        const audience = { ...this.buyerCampaign.audience }
        audience.searchLocation = value

        if (audience.selectedLocations == null) {
          audience.selectedLocations = []
        }

        this.UPDATE_BUYER_CAMPAIGN_AUDIENCE(audience)
      }
    },    
    
    selectedLocations: {
      get() {
        return this.buyerCampaign.audience.selectedLocations
      },
      set(value) {
        const audience = { ...this.buyerCampaign.audience }
        audience.selectedLocations = value
        this.UPDATE_BUYER_CAMPAIGN_AUDIENCE(audience)
      }
    },   

    noIcp: {
      get() {
        return this.buyerCampaign.audience.noIcp ? this.icpOptions[1] : this.icpOptions[0]
      },
      set(value) {
        const audience = { ...this.buyerCampaign.audience }

        audience.noIcp = value === this.icpOptions[1]
        
        this.UPDATE_BUYER_CAMPAIGN_AUDIENCE(audience)

        if (audience.noIcp) {
          this.updateLocationFilterType('States')
        }
      }
    },

    icpOptions: {
      get() {
        return ['Instant Caller Profile (ICP)', 'Inbound Number Area Code']
      }
    },

    locationFilterType() {
      return this.buyerCampaign.audience.locationFilterType
    },

    showLocationStates() {
      return this.searchLocation && this.locationFilterType === 'States'
    },

    showLocationZipCodes() {
      return this.searchLocation && this.locationFilterType === 'ZipCodes'
    },

    showPreviouslyUploadedZipCodes() {
      return !!this.buyerCampaign.audience.zipCodesCount
    },

    getZipCodeFileName(){
      if (this.buyerCampaign.audience.zipCodesFileName) {
        return this.buyerCampaign.audience.zipCodesFileName
      }
      else{
        return "Upload file"
      }
    },

    getLocationFilterTypeDisabledOptions() {
      if (this.buyerCampaign.audience.noIcp) {
        return ['ZipCodes']
      }

      return []      
    },

  },

  methods: {
    ...mapMutations('campaign', ['UPDATE_BUYER_CAMPAIGN_AUDIENCE']),
    updateLocationFilterType(option) {
      const audience = { ...this.buyerCampaign.audience }
      audience.locationFilterType = option
      this.UPDATE_BUYER_CAMPAIGN_AUDIENCE(audience)
    },    
    
    // TODO: check why is this being called 3 times
    parseZipCodes() {
      const reader = new FileReader()

      reader.onload = e => {      
        const zipCodes = e.target.result.split(/[\n\s\t,]+/)
        const audience = { ...this.buyerCampaign.audience }
        audience.zipCodes = zipCodes
        audience.zipCodesFileName = this.zipCodesFile.name
        audience.zipCodesCount = zipCodes.length
        this.UPDATE_BUYER_CAMPAIGN_AUDIENCE(audience)
      }

      reader.readAsText(this.zipCodesFile)
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
