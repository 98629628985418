import PhoneNumber from 'awesome-phonenumber'

import { MINIMUM_INITIAL_DEPOSIT_REQUIREMENT_TOOLTIP } from '@/components/billing/BillingSettings.vue'

export default {
  props: {
    horizontal: {
      type: Boolean,
      default: false
    },

    formGroupClass: {
      type: String,
      default: ''
    },

    labelClass: {
      type: String,
      default: ''
    },

    // This is for `sm` screen size
    width: {
      type: [Number, String],
      default: null,
    },

    widthXs: {
      type: [Number, String],
      default: 12
    },

    widthLg: {
      type: [Number, String],
      default: null
    },

    widthXl: {
      type: [Number, String],
      default: null
    },

    internalPlatformInput: {
      type: Boolean,
      default: false
    },

    rules: {
      type: [String, Object],
      default: ''
    },

    debounce: {
      type: Number,
      default: 0
    },

    inputClass: {
      type: String,
      default: ''
    },

    plaintext: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    validationRules() {
      if (typeof this.rules === 'object') {
        return Object.assign(
          {
            required: this.isRequired ? true : false,
          },
          this.rules
        )
      } else if (typeof this.rules === 'string') {
        return `${this.rules}|${this.isRequired ? 'required' : ''}`
      } else {
        return this.isRequired ? 'required' : ''
      }
    },

    isRequired() {
      return this.$attrs.required != null
    },

    // This is the width for screens with `sm` and above screen size
    inputWidth() {
      if (this.width) {
        return this.width
      }

      if (this.horizontal) {
        return 3
      }
    },
  },

  methods: {
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null
    },

    fetchTooltip(id) {
      return tooltipMap[id]
    },

    phoneNumberFormatter(value) {
      const pn = new PhoneNumber(value, 'US')
      return pn.isValid() ? pn.getNumber('international') : value
    }

  },
}

const tooltipMap = {
  "seller-offer__created-at": "Date the application request was submitted",
  "seller-offer__updated-by": "The user who updated this offer",
  "seller-offer__updated-at": "The date and time when the offer was last updated.",
  "seller-offer__traffic-source-type": "The type of call generation method allowed for this offer.",
  "seller-offer__language": "The language spoken over in the calls.",
  "seller-offer__category": "The type of calls you would like to sell.",
  "seller-offer__description": "The description of the offer.",

  "buyer-create-campaign__campaign-name": "Your campaign name will be used in reporting and to organize your buying efforts.",
  "buyer-create-campaign__campaign-category": "The type of calls you would like to buy.",
  "buyer-create-campaign__campaign-language": "The language of calls you would like to buy.",
  "buyer-create-campaign__campaign-country": "The country you would like to buy calls from.",
  "buyer-create-campaign__destination__routing": "Choose to use Ringba's Real Time Bidding platform or a static destination to manage your call buying.",
  "buyer-create-campaign__destination__dynamic-number": "Use a dynamic destination phone number from your programmatic response instead of a static phone number.",
  "buyer-create-campaign__destination__routing-type": "Select to send your calls to a phone number or a SIP address.",
  "buyer-create-campaign__destination__phone-number": "Purchased calls will be routed to this phone number.",
  "buyer-create-campaign__destination__sip-endpoint": "Purchased calls will go to this SIP URL.",
  "buyer-create-campaign__destination__sip-username": "Your SIP Username. (Optional)",
  "buyer-create-campaign__destination__sip-password": "Your SIP Password. (Optional)",
  "buyer-create-campaign__destination__hours-of-operation": "Call buying will be restricted to your allowed hours of operation.",
  "buyer-create-campaign__destination__timezone": "The selected time zone will be applied to the configured hours of operation.",
  "buyer-create-campaign__destination__max-concurrency": "The maximum number of concurrent calls (cc) you would like to receive at any given time.",
  "buyer-create-campaign__destination__hourly-concurrency": "The maximum number of concurrent calls (cc) you would like to receive at any given hour.",
  "buyer-create-campaign__destination__revenue-type": "Choose a static bid or return your bid in a programmatic response.",
  "buyer-create-campaign__destination__static-revenue-amount": "Enter a static bid for every call instead of a dynamic bid from your programmatic response.",
  "buyer-create-campaign__destination__failure-revenue-amount": "If your system fails to return a bid during the allowed bidding window, or returns an invalid bid, this will be your default bid.",
  "buyer-create-campaign__destination__conversion-call-length-source": "Choose a static conversion duration or return a dynamic conversion duration in your programmatic response.",
  "buyer-create-campaign__destination__call-length": "You will only be billed for calls that meet this minimum conversion duration.",
  "buyer-create-campaign__destination__max-dynamic-duration": "Limit your dynamic conversion duration in seconds.",
  "buyer-create-campaign__destination__request-settings-url": "Ringba X will ping this URL whenever there is a valid call that meets your criteria to receive your bid. Dynamic tokens may be included here to populate caller information.",
  "buyer-create-campaign__destination__request-settings-http-method": "Choose the HTTP method that will be used when we ping your Request URL.",
  "buyer-create-campaign__destination__request-settings-content-type": "Configure the Content-Type of your HTTP request.",
  "buyer-create-campaign__destination__request-settings-body": "Configure your HTTP request body. Dynamic tokens may be included here to populate caller information.",
  "buyer-create-campaign__destination__request-settings-headers": "Configure custom headers that will be used when we ping your Request URL.",
  "buyer-create-campaign__destination__parsing-type": "Select what method you would like to use to parse your expression.",
  "buyer-create-campaign__destination__parsing-expression": "This expression will parse the response to Ringba's programmatic bid request.",
  "buyer-create-campaign__traffic-sources__call-type": "Select what type of call generation method(s) you would like to accept.",
  "buyer-create-campaign__traffic-sources__requirements": "Enabling this will require a Jornaya or Trusted form integration and will deny any outbound generated call without a valid token.",
  "buyer-create-campaign__traffic-sources__allowed-sources": "The types of promotional methods you allow for this campaign.",
  "buyer-create-campaign__traffic-sources__allow-toll-free-callers": "Enabling this will allow people to call from a toll free number.",
  "buyer-create-campaign__traffic-sources__allow-duplicate-callers": "Enabling this will allow duplicate callers within 30 days.",
  "buyer-create-campaign__traffic-sources__allow-third-parties": "Enabling this will allow calls generated by third party affiliates that work with our supply side partners.",
  "buyer-create-campaign__audience__line-type": "The type of phone line that the call is originating from.",
  "buyer-create-campaign__audience__search-location": "Select which locations you would like to buy calls from.",
  "buyer-create-campaign__audience__location-no-icp": "Use Location from",
  "buyer-create-campaign__audience__location-filter-type": "Provide location either as a list of US states or a file with ZIP codes.",
  "buyer-create-campaign__audience__upload-zip-codes": "Upload bulk ZIP codes for where you would like to limit your call buying.",
  "buyer-create-campaign__audience__minimum-duration": "You will only be billed for calls once the minimum duration has been reached. The higher the minimum duration the less priority your campaign will be given.",
  "buyer-create-campaign__audience__daily-budget": "Once this budget has been reached this campaign will stop delivering calls.",
  "buyer-create-campaign__audience__bid-per-call": "Your maximum bid per call. You will only be charged the actual market rate for your calls, not to exceed your maximum bid.",
  "buyer-create-campaign__destination__rtb-id": "Enter the RTB Id, from Ringba",
  "buyer-create-campaign__destination__tag-mappings": "Configure custom data mapping that will be used in the RTB request body when we ping your Request URL.",
  
  "buyer-add-funds__minimum-initial-deposit": MINIMUM_INITIAL_DEPOSIT_REQUIREMENT_TOOLTIP,

  "buyer-add-funds-credit-card": "You can only add funds via approved credit cards",

  "buyer-account-budget__daily_budget": "Once this budget has been reached you'll stop receiving calls across all campaigns.",

  "buyer-billing-settings__minimum-initial-deposit": "This is the minimum initial deposit required to start using RingbaX.",
  "buyer-billing-settings__minimum-balance": "This is the minimum balance your account must have to be able to buy calls.",

  "upload_creative_creative_id": "Optional ID for your own creative tracking. This ID will not be shared with buyers.",
  "upload_creative_creative_name": "Optional creative name",
  "upload_creative_files": "Media file or files representing this creative",
  "upload_approval_docs": "CMS approval documents for this creative",
  "upload_creative_category": "Creative category",
  "upload_creative_traffic_source": "Creative traffic source",
  "upload_creative_country": "Creative country",
  "upload_creative_language": "Creative language",
  "upload_creative_cms_id": "SMID (CMS ID) is mandatory for selected category",
  "upload_creative_landing_page_url": "Optional landing page URL of creative",

  "seller-offer-apply__minExpectedBidAmount": "This helps us provide guidance to buyers to get close or exceed what you’re looking for",
  
  "api-token-name": "Set a name for the API token for future reference"
}
