<template>
  <div>
    <div
      v-if="isDashboardNotificationVisible"
      class="dashboard__notification d-flex justify-content-center align-items-center px-3"
    >
      <div class="ml-auto">
        <span class="mr-1">{{ dashboardNotificationMessageDetails.text }}</span>
        <router-link
          v-if="dashboardNotificationMessageDetails.linkText && dashboardNotificationMessageDetails.routeName"
          :to="{ name: dashboardNotificationMessageDetails.routeName }"
          @click.native="dashboardNotificationMessageDetails.hideOnClick && hideDashboardNotification"
        >
          <u>{{ dashboardNotificationMessageDetails.linkText }}</u>
        </router-link>
      </div>

      <font-awesome-icon
        role="button"
        :icon="['fas', 'times']"
        class="ml-auto"
        :class="{'invisible': !dashboardNotificationMessageDetails.hideOnClick}"
        @click="hideDashboardNotification"
      />
    </div>

    <TheHeader />

    <TheSidebar />

    <section
      class="dashboard__section"
      :class="{ 'dashboard__section--expanded': !isSidebarExpanded }"
    >
      <slot />
    </section>
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex'
import Clarity from '@microsoft/clarity'

import TheHeader from '@/components/TheHeader.vue'
import TheSidebar from '@/components/TheSidebar.vue'

export default {
  name: 'LayoutDashboard',

  components: {
    TheHeader,
    TheSidebar
  },

  computed: {
    ...mapState([
      'isSidebarExpanded', 
      'isDashboardNotificationVisible', 
      'dashboardNotificationMessageDetails'
    ]),

    ...mapGetters('auth', ['accountDetails', 'userId']),
  },

  created () {
    const debug = process.env.NODE_ENV !== 'production'

    if (!debug) {
      this.initClarity()
    }
  },

  methods: {
    ...mapActions(['hideDashboardNotification']),

    initClarity() {
      try {
        Clarity.identify(this.userId, '', '', this.accountDetails.name) // No need to mention page Id here
      } catch (error) {
        console.error('Error calling Clarity Identify API', error)
      }
    }
  },
}
</script>

<style lang="scss" scoped>
.dashboard__section {
  margin-left: 180px;
  padding-top: 55px;
}

.dashboard__section--expanded {
  margin-left: 60px;
}

.dashboard__notification {
  position: fixed;
  z-index: $zindex-tooltip;
  top: 0;
  left: 0;
  right: 0;
  background: #{$danger}f5;
  color: $white;
  line-height: 2.5;
  overflow: hidden; 
  box-shadow: 0 0 5px black;

  animation: slideDown 2.5s 1s 1 ease forwards;
  transform: translateY(-50px);
}
</style>
